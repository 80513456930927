<template>
  <div>
    <h4>STAFF LINKS</h4>
    <ul>
      <li>
        <button
          @click="signInUser"
          v-if="!userIsAuthenticated"
          type="button"
          class="btn btn-primary"
        >
          <i class="fa fa-sign-in"></i>Admin sign-in
        </button>
      </li>
      <li>
        <div v-if="userIsAuthenticated">
          <i class="fa fa-user-circle-o"></i>
          Hi {{ getUserFullName }}
        </div>
      </li>
      <li>
        <button
          @click="signOutUser"
          v-if="userIsAuthenticated"
          type="button"
          class="btn btn-primary"
        >
          <i class="fa fa-sign-out"></i> Sign-out
        </button>
      </li>
      <li style="padding-top:15px;">
        <a
          href="https://mail5006.site4now.net/Login.aspx"
          target="_blank"
          style="color: #fff;"
          >Staff E-mail inbox</a
        >
      </li>
    </ul>
    <modal
      name="alert-dialog-account"
      :width="400"
      :height="'auto'"
      :clickToClose="false"
    >
      <div class="panel-primary">
        <div class="panel-heading modal-alert-heading">
          <span class="modal-alert-title">Information</span>
          <button
            @click="closeAlertDialog"
            type="button"
            class="close pull-right"
          >
            <span
              class="fa fa-times-circle modal-alert-close-button-icon"
            ></span>
          </button>
        </div>
        <div class="panel-body modal-alert-body">
          <div class="modal-alert-text-wrapper">
            <span class="fa fa-exclamation-circle modal-alert-icon"></span>
            <span class="modal-alert-text">{{ alertMessage }}</span>
          </div>
          <div class="modal-alert-footer">
            <button
              @click="closeAlertDialog"
              type="button"
              class="btn btn-primary"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      alertMessage: ""
    };
  },
  computed: {
    ...mapGetters(["getUserFullName", "userIsAuthenticated"])
  },
  methods: {
    ...mapActions(["signIn", "signOut"]),
    signInUser: async function() {
      await this.signIn({ vm: this });
    },
    signOutUser: function() {
      this.signOut({ vm: this });
    },
    closeAlertDialog: function() {
      this.$modal.hide("alert-dialog-account");
    }
  }
};
</script>
